.container {
  display: grid;
  grid-template-rows: auto 1fr auto;
  margin: 0 -17px 0 0;

  .detailedInfo {
    display: grid;
    grid-template-rows: repeat(4, 63px);
    grid-template-columns: 1fr 1fr;
    align-items: center;
    margin-top: 12px;
    margin-bottom: 20px;

    &Item:not(:first-of-type, :nth-of-type(2)) {
      justify-content: center;
      height: 100%;

      border-top: 1px solid var(--black-900);

      .additionalInfo {
        color: var(--grey-500);
      }
    }
  }
}
