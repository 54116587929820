.container {
  position: relative;

  background-color: var(--grey-500);
  border-radius: 50%;

  .shadow {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    border-radius: 50%;

    box-shadow: var(--black-100) 0px 0px 7px inset;

    &.withOuterShadow {
      box-shadow: 0px 0px 15px var(--grey-100), var(--black-100) 0px 0px 7px inset;
    }
  }
}
