.overlay {
  position: fixed;
  top: 0;
  z-index: 9999;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  background-color: rgb(0 0 0 / 70%);
}

.container {
  width: 360px;

  background-color: var(--black-500);
  border-radius: 5px;
  outline: none;
}
