.container {
  margin: 16px 24px;

  .header {
    width: 100%;
    height: 1px;
    margin-bottom: -6px;
  }
}

@media (max-width: 630px) {
  .container {
    margin: 16px 12px;

    .header {
      margin: 20px 12px 16px;
    }
  }
}
