.container {
  display: flex;
  flex-direction: column;
  border-left: 1px solid var(--black-900);
  padding: 0 20px;

  &:first-child {
    border: none;
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }
}
