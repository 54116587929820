.zonesNotFoundContainer {
  margin-top: 32px;

  color: var(--grey-300);
  font-size: var(--font-base-size-lg);
  text-align: center;
}

.zonesInfoTable {
  display: table;
  margin-bottom: 20px;
  width: 100%;
}
