.modal {
  max-width: var(--widget-width);
  width: 100%;
  max-height: var(--widget-height);
  overflow-y: scroll;
  padding: 24px;
  font-size: var(--font-navigation-size);

  .title {
    font-size: var(--font-heading-size-md);
    font-weight: 600;
    margin-bottom: 24px;
  }

  .closeButton {
    background-color: var(--grey-400);
    font-weight: 600;
    margin-left: auto;
    margin-right: 0;
    margin-top: 24px;
  }
}
