.container {
  padding: 16px;

  background: var(--black-400);
  border-radius: 5px;

  &.hasBorder {
    border: 1px solid var(--black-900);
  }

  .title {
    font-size: var(--font-heading-size-md);
    font-weight: 600;
    letter-spacing: -0.02em;
    font-feature-settings: 'tnum' on, 'lnum' on;

    margin-bottom: 24px;
  }
}
