.ratingDiff {
  display: flex;
  align-items: center;

  font-weight: 400;
  font-size: var(--font-secondary-size-md);

  .triangle {
    width: 0;
    height: 0;
    margin-right: 2px;
  }

  &.negative {
    color: var(--red);

    .triangle {
      border-top: 8px solid var(--red);
      border-right: 4px solid transparent;
      border-left: 4px solid transparent;
    }
  }

  &.positive {
    color: var(--green);

    .triangle {
      border-right: 4px solid transparent;
      border-bottom: 8px solid var(--green);
      border-left: 4px solid transparent;
    }
  }
}
