.chartContainer {
  display: flex;
  align-items: center;

  hr {
    height: 1px;

    border-top: none;
  }

  .notActiveLine {
    width: 100%;
    margin: 0 2px;

    border-color: var(--black-900);
  }

  .volumeOutLine {
    margin-right: 2px;
    margin-left: 2px;

    border-color: var(--pink);
  }

  .volumeInLine {
    margin-right: 2px;

    border-color: var(--pink);
    opacity: 0.5;
  }
}
