.container {
  position: relative;

  display: flex;
  align-items: center;
  margin: 28px 0;
}

.position {
  width: 17px;
  height: 16px;
  margin-right: 12px;

  &.forPeers {
    width: 12px;
    margin-right: 22px;
  }
}

.smallLogo {
  position: absolute;
  left: 21px;
  z-index: 1;

  width: 18px;
  height: 18px;
}

.logo {
  z-index: 2;

  width: 32px;
  height: 32px;
  margin-right: 12px;

  border: 2px solid var(--black-100);
}

.name {
  width: 100px;
  height: 16px;

  &.wide {
    width: 132px;
  }
}

.info {
  width: calc(100% - 393px);
  height: 16px;

  margin-left: auto;

  &.wide {
    width: calc(100% - 352px);
  }
}

@media (max-width: 880px) {
  .info {
    width: calc(100% - 343px);

    &.wide {
      width: calc(100% - 302px);
    }
  }
}

@media (max-width: 630px) {
  .name {
    width: 80px;

    &.wide {
      width: 112px;
    }
  }

  .info {
    width: calc(100% - 293px);

    &.wide {
      width: calc(100% - 252px);
    }
  }
}

@media (max-width: 375px) {
  .name {
    width: 60px;

    &.wide {
      width: 92px;
    }
  }

  .info {
    width: calc(100% - 243px);

    &.wide {
      width: calc(100% - 202px);
    }
  }
}
