.container {
  position: relative;

  margin-top: 8px;

  .zoneContainer {
    display: flex;
    align-items: center;
    margin-top: 2px;

    .title {
      margin-left: 8px;

      color: var(--white);
      font-weight: 400;
      font-size: var(--font-base-size-md);
    }

    .circle {
      width: 8px;
      height: 8px;

      border-radius: 50%;

      &.source {
        background-color: var(--pink);
      }

      &.target {
        background-color: var(--blue);
      }

      &.volume {
        background-color: var(--white);
      }
    }
  }

  .link {
    position: absolute;
    bottom: 15px;
    left: 3.5px;

    width: 1px;
    height: 10px;

    background-color: var(--grey-500);
  }
}
