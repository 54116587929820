.container {
  width: 100%;

  .table {
    th {
      &:nth-child(2) {
        text-align: left;
      }

      &:last-child {
        padding-right: 24px;
      }
    }

    /* stylelint-disable */
    th,
    tr td {
      &:first-child {
        min-width: 42px; // TODO: calculate dynamically

        padding-right: 0;

        text-align: center;
      }

      &:nth-child(2) {
        left: 42px; // TODO: calculate dynamically

        width: 100%;

        min-width: 220px;
        padding-left: 16px;
      }
    }
  }
}

@media (max-width: 630px) {
  .container {
    .header {
      margin: 20px 12px 16px;

      .titleContainer {
        .subtitle {
          display: none;
        }
      }
    }

    .table {
      th,
      tr td {
        &:first-child {
          min-width: 30px; // TODO: calculate dynamically
        }

        &:nth-child(2) {
          left: 30px; // TODO: calculate dynamically

          min-width: 0;
          max-width: 150px;
          padding-left: 8px;
        }
      }
    }
  }
}
