.title {
  font-size: var(--font-base-size-md);

  .arrowIcon {
    margin: 0 8px;
  }
}

.connection {
  color: var(--orange);
  font-size: var(--font-secondary-size-md);
}
