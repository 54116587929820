.groupTitle {
  color: var(--grey-300);
  font-size: var(--font-map-size-md);
  text-transform: uppercase;
  margin: 8px 16px;
}

.zone {
  cursor: pointer;

  > div {
    padding: 9px 16px;

    &:hover {
      background-color: var(--black-700);

      &:last-child {
        border-radius: 0 0 5px 5px;
      }
    }
  }

  &.activeZone {
    pointer-events: none;

    > div {
      background-color: var(--black-700);
    }
  }
}
