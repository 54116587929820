.container {
  display: flex;
  align-items: center;

  color: var(--grey-500);
  font-size: var(--font-secondary-size-md);

  &.rightAlign {
    justify-content: flex-end;
  }

  .value {
    margin-left: 3px;
  }
}
