.container {
  display: inline-flex;
}

.icon {
  stroke: #ff9900;
}

.title {
  color: var(--white);
}

.link {
  text-decoration: underline;
}
