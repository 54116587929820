.container {
  display: flex;
  align-items: center;

  font-size: var(--font-base-size-lg);

  .logo {
    margin-right: 12px;
  }

  .nameNotActive {
    color: var(--grey-500);
  }

  .ratingDiff {
    margin-left: 4px;
  }
}
