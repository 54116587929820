.marketCapContainer {
  font-size: var(--font-base-size-md);
  white-space: nowrap;

  .marketCapTitle {
    color: var(--grey-500);
  }

  .marketCapValue {
    color: var(--white);
  }
}

@media (max-width: 1080px) {
  .marketCapContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}
