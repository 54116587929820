.container {
  position: relative;

  .header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin: 32px 24px 40px;

    .zoneContainer {
      position: relative;

      grid-area: zoneContainer;

      .selectedZoneInfo {
        display: flex;

        .detailsTitle {
          display: flex;
          align-items: center;

          cursor: pointer;

          .zoneLogo {
            margin-right: 12px;
          }

          .zoneName {
            font-size: var(--font-heading-size-lg);
            font-weight: 600;
            letter-spacing: -0.02em;
            font-feature-settings: 'tnum' on, 'lnum' on;
          }

          .arrowContainer {
            position: relative;
            top: 3px;

            margin-left: 12px;

            svg {
              stroke: var(--white);
            }
          }
        }

        .disclaimer {
          margin-left: 16px;

          svg {
            width: 24px;
            height: 24px;
          }
        }
      }

      .zoneLinks {
        display: grid;
        grid-template-columns: repeat(4, auto);
        gap: 8px;
        align-items: center;
        margin-top: 9px;

        color: var(--grey-500);
        font-size: var(--font-base-size-md);
        font-feature-settings: 'tnum' on, 'lnum' on;
      }
    }

    .tabsContainer {
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
    }

    .periodContainer {
      grid-area: periodContainer;
    }
  }
}

@media (max-width: 880px) {
  .container {
    .header {
      align-items: stretch;
      display: flex;
      flex-direction: column;

      margin: 24px 12px 40px;

      .tabsContainer {
        position: initial;
        left: 0;
        transform: none;
      }

      .zoneContainer {
        .detailsTitle {
          .zoneName {
            font-size: 20px;
            line-height: 25px;
          }

          .arrowContainer {
            top: 0;
          }
        }
      }
    }
  }
}
