.periodInfo {
  margin-top: 16px;
  margin-bottom: 16px;

  color: var(--grey-500);
  font-size: var(--font-secondary-size-md);
  text-align: end;
}

.chartLogo {
  top: calc(50% - 16px) !important; // exclude X axis
}

.chart {
  pointer-events: all;
  position: absolute;
}
