.wrapper {
  position: relative;
  padding: 24px;
  display: flex;
  flex-direction: column;
  min-height: 450px;

  .additionalText {
    margin-top: 8px;

    color: var(--grey-500);
    font-size: var(--font-secondary-size-md);
  }

  .chartControls {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 20px 0;
  }
}

@media (max-width: 630px) {
  .wrapper {
    min-height: 600px;
  }
}
