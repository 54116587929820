.container {
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: auto auto auto;
  grid-template-columns: 1fr 1fr;
  min-height: 85px;
  margin-bottom: 12px;
  padding: 12px;

  &_title {
    margin-bottom: 4px;

    color: var(--grey-500);
    font-size: var(--font-secondary-size-lg);
  }

  &_value {
    margin-bottom: 2px;

    color: var(--white);
    font-size: var(--font-base-size-lg);
  }

  &_pending {
    margin-left: 3px;

    color: var(--grey-500);
    font-size: var(--font-secondary-size-md);
  }

  &_chart {
    grid-row-start: 1;
    grid-row-end: 4;
  }
}
