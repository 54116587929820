.row {
  display: table-row;

  font-size: var(--font-base-size-lg);

  cursor: pointer;

  .cell {
    display: table-cell;
    height: 50px;

    vertical-align: middle;

    &:first-child {
      padding-left: 16px;
    }
  }

  .zoneRating {
    padding-right: 6px;
    font-size: var(--font-secondary-size-md);
    color: var(--grey-500);
  }

  .zoneBaseInfoContainer {
    display: flex;
    align-items: center;
  }

  .zoneContainer {
    display: flex;
    align-items: center;
    flex-grow: 1;
  }

  .disclaimer {
    margin-left: 8px;
  }

  &:hover {
    background-color: var(--black-600);
  }

  .disclaimer {
    margin-left: 8px;
  }
}
