.container {
  .title {
    margin-bottom: 24px;

    color: var(--white);
    font-size: var(--font-heading-size-md);
  }

  .parametersCards {
    --columns: 3;

    display: grid;
    grid-template-columns: repeat(var(--columns), 1fr);
    gap: 28px;
    min-height: 101px;

    .valuesContainer {
      display: flex;
      flex-direction: row;

      .divider {
        height: auto;
        align-self: stretch;
      }
    }

    .parameterCard {
      padding: 24px;

      .valueBlock {
        flex-grow: 1;
      }

      .bondedRateValueContainer {
        display: flex;

        .secondValue {
          color: var(--grey-500);
        }
      }

      .additionalInfo {
        margin-top: 6px;

        color: var(--grey-500);
        font-size: var(--font-secondary-size-md);

        &_value {
          color: var(--white);
        }
      }
    }
  }
}

@media (max-width: 980px) {
  .container {
    .parametersCards {
      --columns: 2;

      gap: 24px;
    }
  }
}

@media (max-width: 630px) {
  .container {
    .parametersCards {
      --columns: 1;

      gap: 24px;
    }
  }
}
