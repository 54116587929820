.chartContainer {
  display: flex;
  grid-area: lineChart;
  align-items: center;

  .volumeIn {
    &Title {
      margin-right: 2px;

      color: var(--blue);
    }
  }

  .volumeOut {
    &Title {
      margin-left: 2px;

      color: var(--pink);
    }
  }
}
