.container {
  position: relative;
  top: 1px;

  display: flex;
  align-items: center;
  padding: 0 3px 8px;

  color: var(--grey-500);
  font-weight: 400;
  font-size: var(--font-heading-size-sm);
  font-style: normal;
  text-align: center;

  border-bottom: 1px solid transparent;

  cursor: pointer;

  .counter {
    margin-left: 10px;
    padding: 4px 6px;

    color: var(--grey-500);
    font-size: var(--font-base-size-lg);
    text-align: center;

    background-color: var(--black-900);
    border-radius: 100px;
    font-feature-settings: 'tnum' on, 'lnum' on;
  }

  &.active {
    color: var(--white);

    border-bottom-color: var(--white);
  }

  &:hover {
    color: var(--white);
  }
}
