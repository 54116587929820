.container {
  position: relative;
  z-index: 2000;

  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 16px;

  .backdrop {
    position: absolute;
    z-index: -1;
    padding: inherit;
    left: 0;

    width: 100%;
    height: 100%;

    background: var(--black-100);
    opacity: 0.7;
    backdrop-filter: blur(8px);
  }

  .logoContainer {
    display: flex;
    flex-direction: column;
    margin-top: 10px;

    .tagline {
      color: var(--grey-500);
      font-size: 9px;
    }
  }

  .headerContent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    margin-bottom: 18px;
    padding-top: 9px;

    border-bottom: 1px solid var(--black-900);

    .menuContainer {
      margin-left: 20px;
    }
  }
}

@media (max-width: 880px) {
  .container {
    position: fixed;
    margin-top: -1px;

    display: flex;
    justify-content: space-between;

    cursor: pointer;
    opacity: 1;
    backdrop-filter: none;

    .backdrop {
      opacity: 1;
    }

    .headerContent {
      width: auto;
      margin-bottom: 0;

      border: none;

      .menuContainer {
        position: fixed;
        top: -100%;
        left: -100%;

        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-left: 0;
        padding-top: 10px;
        opacity: 0;

        background-color: #0e0e16;
        transition: opacity 0.5s linear;
        pointer-events: auto;

        &.opened {
          top: 62px;
          left: 0;

          width: 100%;
          height: 100vh;
          opacity: 1;
        }
      }
    }
  }
}
