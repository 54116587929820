.container {
  display: flex;
  flex-direction: column;
  transform: translate(0, -100%);
  z-index: 1000;

  padding: 8px;

  color: var(--grey-800);
  font-size: var(--font-secondary-size-md);

  background-color: var(--black-400);
  border: 1px solid var(--black-600);
  border-radius: 4px;
  box-shadow: 0px 8px 20px 0px #00000080;
  outline: none;

  .time {
    color: var(--white);
    font-weight: 600;
    text-align: center;
  }

  .tooltipItem {
    display: flex;
    align-items: center;
    margin-top: 4px;

    .description {
      margin-left: 4px;
      margin-right: 19px;
    }

    .value {
      margin-left: auto;
      color: var(--white);
      font-weight: 600;
    }
  }
}
