.searchContainer {
  position: relative;

  display: flex;
  align-items: center;

  .loopIcon {
    position: absolute;
    right: 10px;

    stroke: var(--grey-100);
    visibility: hidden;
  }

  .searchInput:placeholder-shown + .loopIcon {
    visibility: visible;
  }

  .searchInput {
    width: 100%;
    overflow: hidden;

    font-size: var(--font-base-size-md);
    color: var(--white);

    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .searchInput::-webkit-search-cancel-button {
    --icon-color: var(--grey-100);

    display: inline-block;
    width: 12px;
    height: 12px;
    margin-right: 0;
    margin-left: 10px;

    background: linear-gradient(
        45deg,
        transparent 0%,
        transparent 43%,
        var(--icon-color) 45%,
        var(--icon-color) 55%,
        transparent 57%,
        transparent 100%
      ),
      linear-gradient(
        135deg,
        transparent 0%,
        transparent 43%,
        var(--icon-color) 45%,
        var(--icon-color) 55%,
        transparent 57%,
        transparent 100%
      );
    cursor: pointer;

    appearance: none;
  }

  input[type='search']::-webkit-search-cancel-button:hover {
    --icon-color: var(--grey-800);
  }
}
