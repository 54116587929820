.wrapper {
  display: flex;
  flex-flow: column;
  height: 100%;

  header {
    flex: 0 1 auto;
  }

  footer {
    flex: 0 1 auto;
  }
}

.body {
  flex: 1 1 auto;
}

@media (max-width: 880px) {
  .body {
    margin-top: 64px;
  }
}
