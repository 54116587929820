.link {
  display: inline-flex;
  gap: 8px;

  color: inherit;
  text-decoration: none;

  .icon {
    fill: var(--grey-500);
  }

  &.underlined {
    text-decoration-line: underline;
  }

  &:hover {
    color: var(--white);

    fill: var(--white);

    .icon {
      fill: var(--white);
    }
  }
}
