.container {
  .chartLegend {
    display: flex;

    .legendItem {
      .infoGroup {
        display: flex;

        &:first-child {
          margin-bottom: 4px;
        }
      }

      .tokenNamePrice {
        font-size: var(--font-heading-size-sm);
      }

      .tokenName {
        margin-right: 4px;
        margin-left: 8px;

        color: var(--grey-500);
      }

      .tokenPriceDiff {
        margin-right: 4px;

        font-size: var(--font-secondary-size-md);
      }
    }
  }

  .chartControls {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 20px 0;

    .priceSwitcher {
      min-width: 120px;
    }
  }
}

@media (min-width: 980px) and (max-width: 1280px) {
  .container {
    .chartLegend {
      flex-wrap: wrap;

      .legendItem {
        &:first-child {
          flex: 1 0 100%;
          margin-bottom: 24px;
        }

        &:nth-child(2) {
          border: none;
          padding-left: 0;
        }
      }
    }
  }
}

@media (max-width: 630px) {
  .container {
    .chartLegend {
      flex-direction: column;

      .legendItem {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 20px 0;

        border-top: 1px solid var(--black-900);
        border-left: none;
      }
    }
  }
}
