.divider {
  width: 1px;
  height: 100%;
  margin: 0 16px;

  background-color: var(--black-900);

  &.horizontal {
    width: 100%;
    height: 1px;
    margin: 16px 0;
  }
}
