.container {
  height: 100%;
  margin: 0 -16px 0 0;
}

.peerCard {
  display: block;
  min-height: 160px;
  margin-bottom: 16px;
}
