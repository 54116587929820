.container {
  display: inline-block;
  padding: 0 5px;

  background: linear-gradient(-90deg, #2a2a33 0%, #1e1c25 50%, #2a2a33 100%);
  background-size: 400% 400%;
  border: none;

  animation: pulse 1.2s ease-in-out infinite;

  &.rectangle {
    border-radius: 5px;
  }

  &.circle {
    border-radius: 50%;
  }
}

@keyframes pulse {
  0% {
    background-position: 0% 0%;
  }

  100% {
    background-position: -135% 0%;
  }
}
