.container {
  height: calc(100vh - 105px);
  margin: 4px 16px 4px 0;
  z-index: 100;
}

@media (max-width: 630px) {
  .container {
    height: calc(100vh - 85px);
    margin: 16px 8px;
  }
}
