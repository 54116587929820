.icon {
  position: fixed;
  right: 24px;
  bottom: 52px;

  cursor: pointer;
  opacity: 0.7;

  &:hover {
    opacity: 1;
  }
}
