.sm {
  font-size: var(--font-secondary-size-md);
}

.md {
  font-size: var(--font-base-size-md);
}

.lg {
  font-size: var(--font-heading-size-sm);
}
