.cardLegend {
  display: flex;

  .legendItem {
    border-left: 1px solid var(--black-900);
    padding: 0 16px;

    &:first-child {
      border: none;
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }
}

@media (max-width: 630px), (min-width: 980px) and (max-width: 1280px) {
  .cardLegend {
    &.wrapped {
      flex-wrap: wrap;

      .legendItem {
        &:first-child {
          flex: 1 0 100%;
          margin-bottom: 24px;
        }

        &:nth-child(2) {
          border: none;
          padding-left: 0;
        }
      }
    }
  }
}
