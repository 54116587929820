.container {
  td {
    padding: 12px 12px 31px 28px;

    &:first-child {
      vertical-align: middle;
    }

    .arrowContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 60px;
    }

    .position {
      position: relative;
      top: -2px;

      color: var(--grey-500);
      font-size: var(--font-map-size-md);
      font-feature-settings: 'tnum' on, 'lnum' on;
    }

    .title {
      color: var(--grey-500);
      font-size: var(--font-secondary-size-md);

      cursor: pointer;

      &:hover {
        color: var(--white);
      }

      .arrowIcon {
        width: 10px;
        height: 6px;
        margin-left: 7px;
      }
    }
  }

  &.emptyContainer {
    td {
      padding: 0 0 19px;
    }
  }
}

@media (max-width: 630px) {
  .container {
    td {
      .arrowContainer {
        width: 34px;
      }
    }
  }
}
